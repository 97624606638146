import React from "react"
import { PageProps } from "gatsby"

interface PageNotFoundProps {}

const PageNotFound: React.FC<PageProps<PageNotFoundProps>> = ({}) => {
  return <div>Page not found</div>
}

PageNotFound.defaultProps = {}

PageNotFound.propTypes = {}

export default PageNotFound
